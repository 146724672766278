import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanfung/Documents/Projects/Code/gatsby-portfolio/src/templates/journal.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Initial explorations using Cthulu VST for Ableton yielded some really amazing chords. Much love to my home girl Jen for buying me the VST. This song is dedicated to her and her boston terrier, Dobbi. His word is law and his llama is off limits.`}</p>
    <iframe width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/851667484&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div style={{
      "fontSize": "10px",
      "color": "#cccccc",
      "lineBreak": "anywhere",
      "wordBreak": "normal",
      "overflow": "hidden",
      "whiteSpace": "nowrap",
      "textOverflow": "ellipsis",
      "fontFamily": "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
      "fontWeight": "100"
    }}><a href="https://soundcloud.com/alfatest" title="ALFA TEST" target="_blank" style={{
        "color": "#cccccc",
        "textDecoration": "none"
      }}>ALFA TEST</a> · <a href="https://soundcloud.com/alfatest/the-law-of-one-eyed-dobbi-1" title="The Law Of One-Eyed Dobbi" target="_blank" style={{
        "color": "#cccccc",
        "textDecoration": "none"
      }}>The Law Of One-Eyed Dobbi</a></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      